import { createI18n } from 'vue-i18n'

import defaultsEN from './en/default'
import errorEN from './en/errors'
import helpEN from './en/help'
import gdprEn from './gdpr/en'

import defaultsFR from './fr/default'
import errorFR from './fr/errors'
import helpFR from './fr/help'
import gdprFr from './gdpr/fr'

export default createI18n({
	locale: 'fr',
	fallbackLocale: 'fr',
	messages: {
		en: {
			...defaultsEN,
			...gdprEn,
			errors: errorEN,
			help: helpEN
		},
		fr: {
			...defaultsFR,
			...gdprFr,
			errors: errorFR,
			help: helpFR
		},
	}
})
