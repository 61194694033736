export const setMeta = seo => {
	document.title = seo.title || 'Boite Beet Inc.'
	const metas = document.getElementsByTagName('meta')
	metas['og:title'].content = seo.title || ''
	metas['description'].content = seo.description || ''
	metas['og:description'].content = seo.description || ''
	metas['og:image'].content = seo.image?.original || ''
	metas['og:type'].content = seo.type || 'website'
	metas['twitter:card'].content = seo.twitter || 'summary'
}
