<template>
  <div :class="['image__loading', {'--loaded': loaded}]" :style="loadingImage">
    <img v-if="source" :alt="source.data.alt ?? ''"
         :src="source.thumb ?? source.original"
         :srcset="srcset"
         :title="source.data.title ?? ''"
         :sizes="sizes"
         loading="lazy"
         class="image"
         ref="image"
    >
  </div>
</template>

<script>
export const IMAGE_RESOLUTIONS = {
	ld: 20,
	lg: 1920,
	md: 1024,
	original: 1921,
	sm: 800,
	thumb: 150
}

export default {
	name: 'Image',
	props: {
		sizes: {
			type: String,
			default: ''
		},
		source: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			loaded: false
		}
	},
	computed: {
		loadingImage() {
			const image = this.source.ld ?? this.source.original
			return `background-image: url(${image})`
		},
		srcset() {
			const resolutions = Object.keys(IMAGE_RESOLUTIONS)
			return Object.entries(this.source)
				.filter(src => resolutions.includes(src[0]))
				.sort((srcA, srcB) => IMAGE_RESOLUTIONS[srcA[0]] - IMAGE_RESOLUTIONS[srcB[0]])
				.map(src => `${src[1]} ${IMAGE_RESOLUTIONS[src[0]]}w`)
				.join(', ')
		}
	},
	methods: {
		updateLoaded(isLoaded = true) {
			this.loaded = isLoaded
		}
	},
	mounted() {
		if (this.$refs.image.complete) {
			return this.updateLoaded()
		}

		this.$refs.image.addEventListener('load', this.updateLoaded)
	}
}
</script>