import {createStore} from 'vuex'

import {app} from '@/store/app'
import {castings} from '@/store/castings'
import {projects} from '@/store/projects'
import {seo} from '@/store/seo'

const createSlugMap = typeKey => ({beet}) => {
	const data = beet.data[typeKey] || {}
	return Object.values(data).reduce((slugMap, {id, slug}) => ({...slugMap, [slug]: id}), {})
}

export default createStore({
	getters: {
		projectsSlugMap: createSlugMap('projects')
	},
	modules: {
		app,
		castings,
		projects,
		seo,
	}
})
