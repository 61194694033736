import {createRouter, createWebHistory} from 'vue-router'
import {generateRoutes} from '@/extensions/langRouter'
import {ROUTES} from '@/helpers'

const NotFound = () => import('@/views/NotFound.vue')

const routes = [
	{
		path: '/',
		paths: {en: '/'},
		name: ROUTES.HOME,
		component: () => import('@/views/Home.vue')
	},
	{
		path: '/a-propos',
		paths: {en: '/about'},
		name: ROUTES.ABOUT,
		component: () => import('@/views/About.vue')
	},
	{
		path: '/casting',
		paths: {en: '/casting'},
		name: ROUTES.CASTING.INDEX,
		component: () => import('@/views/Casting.vue')
	},
	{
		path: '/projets',
		paths: {en: '/projects'},
		name: ROUTES.PROJECT.INDEX,
		component: () => import('@/views/Projects.vue')
	},
	{
		path: '/projets/:slug',
		paths: {en: '/projects/:slug'},
		name: ROUTES.PROJECT.SINGLE,
		props: true,
		component: () => import('@/views/Project.vue')
	},
	{
		path: '/conditions-dutilisation',
		paths: {en: '/terms-of-use'},
		name: ROUTES.TERMS,
		component: () => import('@/views/Terms.vue')
	},
	{
		path: '/404',
		name: 'not-found',
		component: NotFound
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'catch-all',
		component: NotFound
	}
]

const localizedRoutes = generateRoutes(routes, 'fr', 'en')

export default createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	linkActiveClass: '-active',
	linkExactActiveClass: '-exact-active',
	routes: localizedRoutes,
	scrollBehavior(to, from) {
		if (to.name === from.name) {
			if (to.hash) return {el: to.hash, behavior: 'smooth'}

			if(!to.name.includes(ROUTES.PROJECT.SINGLE)) {
				return {top: 0, behavior: 'smooth'}
			}
		}

		return new Promise((resolve) => {
			const resolveTarget = to.hash ? {el: to.hash, behavior: 'smooth'} : { top: 0 }
			const delay = to.hash ? 300 : 200

			setTimeout(() => {
				resolve(resolveTarget)
			}, delay)
		})
	}
})