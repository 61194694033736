<template>
  <nav class="navbar" :class="{'-open' : open, '-scroll': scrolled}">
    <div class="navbar__content" v-reveal="{delay: 1000}">
      <LangRouterLink @click="open = false" :to="{name: ROUTES.HOME}" class="navbar-logo">
        <img src="@/assets/images/beetprod.svg" width="184" height="52" alt="Beet Productions">
      </LangRouterLink>
      <template v-if="mq.mdPlus">
        <div class="row -mobile -align-center">
          <LangRouterLink :to="{name: ROUTES.PROJECT.INDEX}" class="navbar-item">{{ $t('projects') }}</LangRouterLink>
          <LangRouterLink :to="{name: ROUTES.ABOUT}" class="navbar-item">{{ $t('about') }}</LangRouterLink>

          <LangSwitch/>

          <div class="navbar-contact">
            <a :href="`mailto:${$beet.options.contact.email}`" class="link">{{ $beet.options.contact.email }}</a>
            <a :href="`tel:+1${$beet.options.contact.phone.replaceAll(/[^0-9]/g, '')}`"
               class="link">{{ $beet.options.contact.phone }}</a>
          </div>
        </div>
      </template>
      <button v-else :class="['navbar__hamburger', {'--opened': open}]" @click="open = !open">
        <span v-for="i in 3" :key="i"/>
      </button>
    </div>
    <div v-if="open" class="navbar__mobile-links">
      <LangRouterLink @click="open = false" :to="{name: ROUTES.PROJECT.INDEX}" class="navbar__mobile-link">
        {{ $t('projects') }}
      </LangRouterLink>
      <LangRouterLink @click="open = false" :to="{name: ROUTES.ABOUT}" class="navbar__mobile-link"> {{ $t('about') }}</LangRouterLink>
      <LangRouterLink @click="open = false" :to="{name: ROUTES.HOME, hash: '#contact'}" class="navbar__mobile-link">
        Contact
      </LangRouterLink>
      <LangSwitch mobile/>
    </div>
  </nav>
</template>

<script>
import {LangRouterLink} from '@/components/global'
import {LangSwitch} from '@/components/navigation'
import {UsesRoutesMixin} from '@/mixins'

export default {
	name: 'SiteNav',
	inject: ['mq'],
	mixins: [UsesRoutesMixin],
	components: {LangRouterLink, LangSwitch},
	data() {
		return {
			open: false,
			scrolled: false
		}
	},
	watch: {
		open() {
			this.toggleLockClass()
		},
		'$i18n.locale': {
			handler() {
				this.toggleLockClass()
			},
			immediate: true
		}
	},
	methods: {
		handleResize() {
			this.open = false
		},
		handleScroll() {
			this.scrolled = window.scrollY > 0
		},
		toggleLockClass() {
			this.open ? document.body.classList.add('--lock') : document.body.classList.remove('--lock')

		}
	},
	mounted() {
		window.addEventListener('resize', this.handleResize)
		window.addEventListener('scroll', this.handleScroll, {passive: true})
	},
	unmounted() {
		window.removeEventListener('resize', this.handleResize)
		window.removeEventListener('scroll', this.handleScroll)
	}
}
</script>
