<template>
  <button @click="$i18n.locale = toLang" :class="['navbar-lang', {'-mobile': mobile}]">
    {{ toLang }}
  </button>
</template>

<script>
export default {
	name: 'LangSwitch',
	props: {
		mobile: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		toLang() {
			return this.$i18n.availableLocales.filter(locale => locale !== this.$i18n.locale)[0]
		}
	}
}
</script>