import {setMeta} from '@/helpers/metaHelpers.js'

export const seo = {
	actions: {
		updateCastingSeo(context, {casting, image}) {
			let description = casting.description.replace(/(<([^>]+)>)/ig, ' ')
			description = description.length > 300 ? `${description.substring(0, 300)}...` : description
			setMeta({
				description: description,
				image: image,
				title: casting.title,
				twitter: description
			})
		},
		updateProjectSeo(context, project) {
			setMeta({
				description: project.description,
				image: project.image,
				title: `${project.title} | Beet Productions`,
				twitter: project.description
			})
		}
	}
}