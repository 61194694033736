export const app = {
	state: {
		beetUrl: 'https://boitebeet.com',
		ready: false
	},
	mutations: {
		updateBeetUrl(state, url) {
			if (url) state.beetUrl = url
		},
		updateReadyState(state, isReady = false) {
			state.ready = isReady
		},
	},
	actions: {
		updateBeetUrl(context, url) {
			if (url) context.commit('updateBeetUrl', url)
		},
		updateReadyState(context, isReady = false) {
			context.commit('updateReadyState', isReady)
		},
	}
}