import http from '@/extensions/http'
import {ENDPOINTS} from '@/helpers'

export const castings = {
	actions: {
		async storeCasting(context, casting) {
			try {
				await http.$post(ENDPOINTS.ACTOR.STORE, http.formatFileRequest(casting))
				return {success: true}
			} catch ({response}) {
				if(response) return {success: false, error: response.data}
			}
		}
	}
}