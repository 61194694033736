export const ROUTES = {
	ABOUT: 'about',
	CASTING: {
		INDEX: 'casting'
	},
	HOME: 'home',
	PROJECT: {
		INDEX: 'projects',
		SINGLE: 'project'
	},
	TERMS: 'terms'
}