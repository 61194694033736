<template>
  <main id="app" class="layout" v-if="$store.state.app.ready">
    <SiteNav/>
    <RouterView v-slot="{ Component }">
      <transition name="page" mode="out-in">
        <component :is="Component" :key="$route.path"/>
      </transition>
    </RouterView>
    <SiteFooter :key="`footer-${$route.path}`" />
    <Gdpr/>
  </main>
</template>

<script>
import {SiteNav} from '@/components/navigation'
import {SiteFooter} from '@/components/sections'
import Gdpr from '@/components/gdpr/Gdpr.vue'
import {useLangRouter} from '@/extensions/langRouter'

export default {
	name: 'App',
	setup() {
		useLangRouter()
	},
	components: {Gdpr, SiteFooter, SiteNav},
	watch: {
		'$i18n.locale': {
			immediate: true,
			handler: async function (to) {
				await this.$store.dispatch('updateReadyState')
				await this.$beet.fetchCoreData(to)
				await this.$store.dispatch('updateBeetUrl', import.meta.env.VITE_APP_BEET_URL)
				await this.$store.dispatch('setProjects', Object.values(this.$beet.projects))
				await this.$store.dispatch('updateReadyState', true)
			}
		}
	}
}
</script>
