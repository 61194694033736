export const projects = {
	state: {
		ids: [],
		items: [],
		latests: []
	},
	mutations: {
		setProjects(state, projects) {
			state.items = projects.sort((a, b) => {
				const aDate = new Date(`${a.year}-${a.month}`)
				const bDate = new Date(`${b.year}-${b.month}`)
				const aTime = aDate.getTime()
				const bTime = bDate.getTime()
				if (aTime < bTime) return 1
				else if (aTime > bTime) return -1
				return 0
			})

			state.ids = [...state.items].map(({id}) => id)
			state.latests = [...state.ids].slice(0, 3)
		}
	},
	actions: {
		setProjects(context, projects) {
			context.commit('setProjects', projects)
		}
	}
}