export default {
	about: 'About',
	awaiting_server: 'Waiting for a server response...',
	backToProjects: 'Back to projects',
	capsules: 'Series clips',
	casting: 'Casting',
	close: 'Close',
	discover: 'Discover Our Sister Company: Beet inc.',
	follow: 'Follow us',
	footer: 'Website designed and developed by',
	form: {
		gender: 'Choose an apparent gender'
	},
	mandat: 'description',
	nextProject: 'Next project',
	no: 'No',
	optional: 'Optional',
	projects: 'Projects',
	seeProject: 'Watch project',
	services: 'services',
	terms: 'Terms and conditions',
	video: {
		play: 'Play the video'
	},
	yes: 'Yes'
}
