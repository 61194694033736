export default {
	about: 'À propos',
	awaiting_server: 'En attente d\'une réponse du serveur...',
	backToProjects: 'Retour aux projets',
	capsules: 'Capsules de la série',
	casting: 'Casting',
	close: 'Fermer',
	discover: 'Découvrez notre compagnie soeur: Beet inc.',
	follow: 'Suivez-nous',
	footer: 'Site web conçu et développé par',
	form: {
		gender: 'Choisir un genre apparent'
	},
	mandat: 'description',
	nextProject: 'Prochain projet',
	no: 'Non',
	optional: 'Optionnel',
	projects: 'Projets',
	seeProject: 'Visionner le projet',
	services: 'services',
	terms: 'Conditions d\'utilisation',
	video: {
		play: 'Jouer la vidéo'
	},
	yes: 'Oui'
}
