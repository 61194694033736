import axios from 'axios'
import i18n from '@/i18n/index.js'

const axiosInstance = axios.create({baseURL: import.meta.env.VITE_APP_API_URL})

axiosInstance.formatFileRequest = (data) => {
	const formattedData = new FormData()

	Object.entries(data).forEach(([key, value]) => {
		if (value !== null) {
			if (value instanceof Array || value instanceof FileList) {
				Object.values(value).forEach(arrayValue => {
					formattedData.append(`${key}[]`, arrayValue)
				})
			} else {
				formattedData.append(key, value)
			}
		}
	})

	return formattedData
}

axiosInstance.localizedUrl = url => `${url}?lang=${i18n.global.locale.value}`

axiosInstance.$post = (url, data) =>
	new Promise((resolve, reject) => {
		axiosInstance
			.post(axiosInstance.localizedUrl(url), data)
			.then(response => resolve(response))
			.catch(error => reject(error))
	})

axiosInstance.$get = (url) =>
	new Promise((resolve, reject) => {
		axiosInstance
			.get(axiosInstance.localizedUrl(url))
			.then(response => resolve(response))
			.catch(error => reject(error))
	})

export default axiosInstance