<template>
  <footer>
    <FooterContact/>
    <div class="footer row -spread">
      <div>{{ `©${new Date().getFullYear()} Beet Productions` }}</div>
      <LangRouterLink :to="{name: ROUTES.TERMS}" class="footer__terms">{{ $t('terms') }}</LangRouterLink>
      <a :href="$store.state.app.beetUrl" target="_blank" class="footer__beet">
        <span>{{ $t('footer') }}</span>
        <em>{{ ' Beet' }}</em>
      </a>
    </div>
  </footer>
</template>

<script>
import {FooterContact} from '@/components/blocks'
import {LangRouterLink} from '@/components/global'
import {UsesRoutesMixin} from '@/mixins'

export default {
	name: 'SiteFooter',
	mixins: [UsesRoutesMixin],
	components: {FooterContact, LangRouterLink}
}
</script>
