export default {
	empty: {
		age_ranges: 'Please select at least 1 apparent age category.',
		availability: 'Please indicate your availability.',
		birthdate: 'Please enter your birthdate.',
		casting: 'Please select at least 1 category.',
		email: 'Please enter your email address.',
		first_name: 'Please enter your first name.',
		gender: 'Please select an apparent gender.',
		hourly_rate: 'Please enter your hourly rate to the nearest unit.',
		last_name: 'Please enter your last name.',
		message: 'Please enter a message.',
		phone: 'Please enter your phone number.',
		photos: 'Please import some photos.',
		portfolio: 'Please import your portfolio as a pdf document.',
		portrait: 'Please import a portrait picture of yourself.',
		uda: 'Please indicate if you are part of l\'union des artistes.',
		uda_id: 'Please enter a Union des Artises ID',
		website: 'Please enter an url link.'
	},
	pattern: {
		age_ranges: 'Please select at least 1 apparent age category.',
		availability: 'Please indicate a valid availability.',
		birthdate: 'Please enter a valid birthdate.',
		casting: 'Please select at least 1 category.',
		email: 'Please enter a valid email address.',
		first_name: 'Please enter a valid first name.',
		gender: 'Please select an apparent gender.',
		hourly_rate: 'Please enter your hourly rate to the nearest unit. (Max 999)',
		last_name: 'Please enter a valid last name.',
		message: 'Please enter a valid message.',
		phone: 'Please enter a 10 digits phone number.',
		photos: 'Please import some photos.',
		portfolio: 'Please import your portfolio as a pdf document.',
		portrait: 'Please import a portrait picture of yourself.',
		uda: 'Please indicate if you are part of l\'union des artistes.',
		uda_id: 'Please enter a valid Union des Artises ID (6 digits number).',
		website: 'Please enter a valid url link.'
	}
}