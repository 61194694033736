<template>
  <div id="contact" class="footer-contact">
    <h2 class="footer-contact__title" v-reveal>
      {{ contact.title }}
    </h2>
    <div class="row -wrap footer-contact__buttons">
      <a :href="`mailto:${contact.email}`" target="_blank" class="button footer-contact__button"
         v-reveal="{delay: 75}">
        {{ contact.email }}
        <Icon id="arrow"/>
      </a>

      <a :href="`tel:+1${contact.phone.replaceAll(/[^0-9]/g, '')}`" target="_blank" class="button footer-contact__button"
         v-reveal="{delay: 150}">
        {{ contact.phone }}
        <Icon id="arrow"/>
      </a>

      <a href="https://www.google.com/maps/place/Beet/@46.8120545,-71.2260357,17z/data=!3m1!4b1!4m5!3m4!1s0x4cb89670fdfca8cb:0xa4422cd36faa9532!8m2!3d46.8120545!4d-71.2238417"
         target="_blank" class="button footer-contact__button -large -multi" v-reveal="{delay: 225}">
        {{ contact.address }}
        <Icon id="arrow"/>
      </a>
    </div>
    <div class="footer-contact__line" v-reveal/>
    <div v-if="!mq.mdPlus" class="footer-contact__mobile-social">
      <div class="footer-contact__item -big">
        <div> {{ $t('follow') }}</div>
        <div class="row -spread footer-contact__item--link -socials">
          <a v-if="social.vimeo" :href="social.vimeo" alt="vimeo" target="_blank">
            <Icon id="vimeo"/>
          </a>
          <a v-if="social.instagram" :href="social.instagram" alt="instagram" target="_blank">
            <Icon id="instagram"/>
          </a>
          <a v-if="social.facebook" :href="social.facebook" alt="tiktok" target="_blank">
            <Icon id="facebook"/>
          </a>
          <a v-if="social.linkedin" :href="social.linkedin" alt="linkedin" target="_blank">
            <Icon id="linkedin"/>
          </a>
        </div>
      </div>
    </div>
    <div class="row -spread -full footer-contact__links">
      <div class="row__item -span-4 footer-contact__item" :class="{'-span-6' : !mq.mdPlus}"
           v-reveal="{delay: 75}">
        <div>{{ contact.salesText }}</div>
        <a :href="`mailto:${contact.salesEmail}`" target="_blank"
           class="footer-contact__item--link link">{{ contact.salesEmail }}</a>
      </div>
      <div class="row__item -span-4 footer-contact__item" :class="{'-span-6' : !mq.mdPlus}"
           v-reveal="{delay: 150}">
        <div>{{ contact.jobsText }}</div>
        <a :href="`mailto:${contact.jobsEmail}`" target="_blank" class="footer-contact__item--link link">{{
            contact.jobsEmail
          }}</a>
      </div>
      <div class="row__item -span-4 footer-contact__item" v-if="mq.mdPlus" v-reveal="{delay: 225}"
           style="justify-content: center">
        <div> {{ $t('follow') }}</div>
        <div class="row -spread footer-contact__item--link -socials">
          <a v-if="social.vimeo" :href="social.vimeo" alt="vimeo" target="_blank">
            <Icon id="vimeo"/>
          </a>
          <a v-if="social.instagram" :href="social.instagram" alt="instagram" target="_blank">
            <Icon id="instagram"/>
          </a>
          <a v-if="social.facebook" :href="social.facebook" alt="tiktok" target="_blank">
            <Icon id="facebook"/>
          </a>
          <a v-if="social.linkedin" :href="social.linkedin" alt="linkedin" target="_blank">
            <Icon id="linkedin"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Icon} from '@/components/global'

export default {
	name: 'FooterContact',
	inject: ['mq'],
	components: {Icon},
	computed: {
		contact() {
			return this.$beet.options.contact || {}
		},
		social() {
			return this.$beet.options.socials || {}
		}
	}
}
</script>
