export default {
	empty: {
		age_ranges: 'Veuillez sélectionner au minimum 1 catégorie d\'âge apparent.',
		availability: 'Veuillez indiquer vos disponibilités.',
		birthdate: 'Veuillez entrer votre date de naissance.',
		casting: 'Veuillez sélectionner au minimum 1 catégorie.',
		email: 'Veuillez entrer votre adresse courriel.',
		first_name: 'Veuillez entrer votre prénom.',
		gender: 'Veuillez sélectioner un genre apparent.',
		hourly_rate: 'Veuillez entrer votre taux horaire à l\'unité près. (Max 999)',
		last_name: 'Veuillez entrer votre nom de famille.',
		message: 'Veuillez entrer un message.',
		phone: 'Veuillez entrer votre numéro de téléphone.',
		photos: 'Veuillez importer des photos.',
		portfolio: 'Veuillez importer un portfolio au format de document pdf.',
		portrait: 'Veuillez importer une photo de portrait.',
		uda: 'Veuillez indiquer si vous faites partie de l\'union des artistes.',
		uda_id: 'Veuillez entrer un identifiant de l\'union des artistes.',
		website: 'Veuillez entrer un lien url.'
	},
	pattern: {
		age_ranges: 'Veuillez sélectionner au minimum 1 catégorie d\'âge apparent.',
		availability: 'Veuillez indiquer des disponibilités valides.',
		birthdate: 'Veuillez entrer votre date de naissance valide.',
		casting: 'Veuillez sélectionner au minimum 1 catégorie.',
		email: 'Veuillez entrer une adresse courriel valide.',
		first_name: 'Veuillez entrer un prénom valide.',
		gender: 'Veuillez sélectioner un genre apparent.',
		hourly_rate: 'Veuillez entrer votre taux horaire à l\'unité près en chiffres seulement. (Max 999)',
		message: 'Veuillez entrer un message valide.',
		last_name: 'Veuillez entrer un nom de famille valide.',
		phone: 'Veuillez entrer un numéro de téléphone à 10 chiffres.',
		photos: 'Veuillez importer des photos valides.',
		portfolio: 'Veuillez importer un portfolio au format de document pdf.',
		portrait: 'Veuillez importer une photo de portrait.',
		uda: 'Veuillez indiquer si vous faites partie de l\'union des artistes.',
		uda_id: 'Veuillez entrer un identifiant de l\'union des artistes valide (Nombre à 6 chiffres).',
		website: 'Veuillez entrer un lien url valide.'
	}
}